<template>
  <base-loading :show="isShowLoading" />
  <sidebar-menu
    class="app-sidebar"
    :menu="menu"
    :hideToggle="true"
    :relative="true"
    :collapsed="props.collapsed"
    :showOneChild="props.showOneChild"
    theme="default"
  />
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import $http from '@/plugins/axios';

const userId = ref('');
const isShowLoading = ref(false);

const roles = ref([]);

onMounted(async () => {
  getUserId();
  await getRoles();
  updateMenu();
});

const getUserId = () => {
  const retrievedUser = localStorage.getItem('user');
  const userObject = JSON.parse(retrievedUser);
  userId.value = userObject.id;
};

async function getRoles() {
  isShowLoading.value = true;
  await $http
    .get(
      `/user/getById?id=${userId.value}`
    )
    .then(({ data }) => {
      roles.value = data.roles.map(e => e.name);
    })
    .catch((e) => {
      return e;
    });
  isShowLoading.value = false;
}

const updateMenu = () => {
  ///////////////CADASTROS//////////////////
  const cadastrosIndex = menu.value.findIndex(menu => menu.title === 'Cadastros');
  if (cadastrosIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[cadastrosIndex].child = [
        {
          href: { name: 'companyList' },
          title: 'Empresas',
          icon: 'mdi mdi-briefcase',
        },
        {
          href: { name: 'contractCompanyList' },
          title: 'Contratos',
          icon: 'mdi mdi-folder-multiple',
        },
        {
          href: { name: 'campaignList' },
          title: 'Ordem de Serviço',
          icon: 'mdi mdi-clipboard-text',
        },
        {
          title: 'Produtos',
          icon: 'mdi mdi-account-multiple',
          child: [
            {
              href: { name: 'productList' },
              title: 'Lista / Cadastrar',
              icon: 'mdi mdi-tag-multiple',
            },
            {
              href: { name: 'productTypeList' },
              title: 'Tipo de Produto',
              icon: 'mdi mdi-tag',
            },
          ],
        },
        {
          title: 'Usuários',
          icon: 'mdi mdi-account-multiple',
          child: [
            {
              href: { name: 'AwardedUserList' },
              title: 'Contatos / Premiados',
              icon: 'mdi mdi-account-multiple',
            },
            {
              href: { name: 'OnlypayUsersList' },
              title: 'Usuários Onlypay',
              icon: 'mdi mdi-account-multiple',
            },
          ],
        },
      ];
    }

    if (roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Comercial')) {
      menu.value[cadastrosIndex].child = [
        {
          href: { name: 'companyList' },
          title: 'Empresas',
          icon: 'mdi mdi-briefcase',
        },
        {
          href: { name: 'contractCompanyList' },
          title: 'Contratos',
          icon: 'mdi mdi-folder-multiple',
        },
      ];
    }

    if (roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')) {
      menu.value[cadastrosIndex].child = [
        {
          href: { name: 'companyList' },
          title: 'Empresas',
          icon: 'mdi mdi-briefcase',
        },
        {
          href: { name: 'contractCompanyList' },
          title: 'Contratos',
          icon: 'mdi mdi-folder-multiple',
        },
        {
          title: 'Usuários',
          icon: 'mdi mdi-account-multiple',
          child: [
            {
              href: { name: 'AwardedUserList' },
              title: 'Contatos / Premiados',
              icon: 'mdi mdi-account-multiple',
            },
            {
              href: { name: 'OnlypayUsersList' },
              title: 'Usuários Onlypay',
              icon: 'mdi mdi-account-multiple',
            },
          ],
        },
      ];
    }

    if (
      roles.value.includes('Financeiro') && roles.value.includes('Comercial')
      && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
    ) {
      menu.value[cadastrosIndex].child = [
        {
          href: { name: 'companyList' },
          title: 'Empresas',
          icon: 'mdi mdi-briefcase',
        },
        {
          href: { name: 'contractCompanyList' },
          title: 'Contratos',
          icon: 'mdi mdi-folder-multiple',
        },
        {
          title: 'Usuários',
          icon: 'mdi mdi-account-multiple',
          child: [
            {
              href: { name: 'AwardedUserList' },
              title: 'Contatos / Premiados',
              icon: 'mdi mdi-account-multiple',
            },
            {
              href: { name: 'OnlypayUsersList' },
              title: 'Usuários Onlypay',
              icon: 'mdi mdi-account-multiple',
            },
          ],
        },
      ];
    }

    if (roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial')) {
      menu.value[cadastrosIndex].child = [
        {
          title: 'Usuários',
          icon: 'mdi mdi-account-multiple',
          child: [
            {
              href: { name: 'AwardedUserList' },
              title: 'Contatos / Premiados',
              icon: 'mdi mdi-account-multiple',
            },
            {
              href: { name: 'OnlypayUsersList' },
              title: 'Usuários Onlypay',
              icon: 'mdi mdi-account-multiple',
            },
          ],
        },
      ];
    }

    if (
      roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial') && !roles.value.includes('Atendimento')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial') && !roles.value.includes('Atendimento')
    ) {
      menu.value.splice(cadastrosIndex, 1);
    }

  }

  ///////////////Pedidos de Carga//////////////////
  const pedidosIndex = menu.value.findIndex(menu => menu.title === 'Pedidos de Carga');
  if (pedidosIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[pedidosIndex].child = [
        {
          href: { name: 'orderAwardedList' },
          title: 'Listar / Cadastrar',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'cardsReserveList' },
          title: 'Reserva de Cartões',
          icon: 'mdi mdi-arrow-right',
        },
      ];
    }

    if (roles.value.includes('Atendimento') || roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')) {
      menu.value[pedidosIndex].child = [
        {
          href: { name: 'orderAwardedList' },
          title: 'Listar / Cadastrar',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }
  }

  ///////////////NOTAS FISCAIS//////////////////
  const notasFiscaisIndex = menu.value.findIndex(menu => menu.title === 'Notas Fiscais');
  if (notasFiscaisIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Financeiro')) {
      menu.value[notasFiscaisIndex].child = [
        {
          href: { name: 'invoicesRequestList' },
          title: 'Faturamento',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'invoicesList' },
          title: 'Listar / Cadastrar',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Operacional') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
    ) {
      menu.value.splice(notasFiscaisIndex, 1);
    }

  }

  ///////////////VINCULAR SALDO PISCINA//////////////////
  const pedidosSemNotaIndex = menu.value.findIndex(menu => menu.title === 'Vincular Saldo Piscina');
  if (pedidosSemNotaIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Financeiro')) {
      menu.value[pedidosSemNotaIndex].child = [
        {
          href: { name: 'NonInvoiceOrdersList' },
          title: 'Listar / Vincular',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Operacional') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
    ) {
      menu.value.splice(pedidosSemNotaIndex, 1);
    }
  }

  ///////////////APROVAR CARGAS//////////////////
  const aprovarCargasIndex = menu.value.findIndex(menu => menu.title === 'Aprovar Cargas');
  if (aprovarCargasIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional') && roles.value.includes('Financeiro') ) {
      menu.value[aprovarCargasIndex].child = [
        {
          href: { name: 'RechargesNotUnlocked' },
          title: 'Não Desbloqueados',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'RechargeListFinancial' },
          title: 'Financeiro',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'RechargeListOperational' },
          title: 'Operacional',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (roles.value.includes('Operacional') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')) {
      menu.value[aprovarCargasIndex].child = [
        {
          href: { name: 'RechargesNotUnlocked' },
          title: 'Não Desbloqueados',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'RechargeListOperational' },
          title: 'Operacional',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')) {
      menu.value[aprovarCargasIndex].child = [
        {
          href: { name: 'RechargeListFinancial' },
          title: 'Financeiro',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'RechargesNotUnlocked' },
          title: 'Não Desbloqueados',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'RechargeListOperational' },
          title: 'Operacional',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')
        || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')
        || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')
        || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')
    ) {
      menu.value.splice(aprovarCargasIndex, 1);
    }

  }

  ///////////////RECARGAS//////////////////
  const recargasIndex = menu.value.findIndex(menu => menu.title === 'Recargas');
  if (recargasIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[recargasIndex].child = [
        {
          href: { name: 'rechargesList' },
          title: 'Consultar Recargas',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
    ) {
      menu.value.splice(recargasIndex, 1);
    }

  }

  ///////////////RELATÓRIOS//////////////////
  const relatoriosIndex = menu.value.findIndex(menu => menu.title === 'Relatórios');
  if (relatoriosIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[relatoriosIndex].child = [
        {
          href: { name: 'OrdersReportList' },
          title: 'Pedidos de Carga',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'operationStatementList' },
          title: 'Extrato',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'transactionsList' },
          title: 'Transações',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'cardsReportList' },
          title: 'Cartões',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'financialReportList' },
          title: 'Financeiro',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Comercial')) {
      menu.value[relatoriosIndex].child = [
        {
          href: { name: 'transactionsList' },
          title: 'Transações',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'financialReportList' },
          title: 'Financeiro',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro')) {
      menu.value[relatoriosIndex].child = [
        {
          href: { name: 'OrdersReportList' },
          title: 'Pedidos de Carga',
          icon: 'mdi mdi-arrow-right',
        },
      ];
    }

    if (roles.value.includes('Finaceiro') && roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')) {
      menu.value[relatoriosIndex].child = [
        {
          href: { name: 'OrdersReportList' },
          title: 'Pedidos de Carga',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'transactionsList' },
          title: 'Transações',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'financialReportList' },
          title: 'Financeiro',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial')
      || !roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial')
      || !roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Financeiro') && !roles.value.includes('Comercial') ) {
      menu.value.splice(relatoriosIndex, 1);
    }

  }

  ///////////////EMBOSSING//////////////////
  const embossingIndex = menu.value.findIndex(menu => menu.title === 'Embossing');
  if (embossingIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[embossingIndex].child = [
        {
          href: { name: 'embossingList' },
          title: 'Listar / Cadastrar',
          icon: 'mdi mdi-arrow-right',
        },
      ];
    }

    if (
      roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')
    ) {
      menu.value.splice(embossingIndex, 1);
    }

  }

  ///////////////USUÁRIOS (HISTÓRICO)//////////////////
  const usuariosHistoricoIndex = menu.value.findIndex(menu => menu.title === 'Usuários');
  if (usuariosHistoricoIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional') || roles.value.includes('Atendimento') || roles.value.includes('Financeiro')) {
      menu.value[usuariosHistoricoIndex].child = [
        {
          href: { name: 'users' },
          title: 'Histórico',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      !roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
    ) {
      menu.value.splice(usuariosHistoricoIndex, 1);
    }
  }

  ///////////////CARTÕES//////////////////
  const cardsIndex = menu.value.findIndex(menu => menu.title === 'Cartões');
  if (cardsIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Operacional')) {
      menu.value[cardsIndex].child = [
        {
          href: { name: 'cardsList' },
          title: 'Consultar Cartões',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'cardsTransfer' },
          title: 'Transferências',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'duplicateCard' },
          title: '2º via de cartão',
          icon: 'mdi mdi-arrow-right',
        },
        {
          href: { name: 'batchActions' },
          title: 'Ações em Lote',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional')) {
      menu.value[cardsIndex].child = [
        {
          href: { name: 'cardsList' },
          title: 'Consultar Cartões',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Financeiro') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Operacional') && !roles.value.includes('Atendimento')
    ) {
      menu.value.splice(cardsIndex, 1);
    }

  }

  ///////////////APROVAÇÕES//////////////////
  const aprovacoesIndex = menu.value.findIndex(menu => menu.title === 'Aprovações');
  if (aprovacoesIndex !== -1) {

    if (roles.value.includes('Administrador')) {
      menu.value[aprovacoesIndex].child = [
        {
          href: { name: 'approvalsList' },
          title: 'Consultar Aprovações',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Operacional') && !roles.value.includes('Administrador')
      || roles.value.includes('Financeiro') && !roles.value.includes('Administrador')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador')) {
      menu.value.splice(aprovacoesIndex, 1);
    }

  }

  ///////////////TRANSFERÊNCIA DE SALDO//////////////////
  const transferenciasSaldoIndex = menu.value.findIndex(menu => menu.title === 'Transferências de Saldo');
  if (transferenciasSaldoIndex !== -1) {

    if (roles.value.includes('Administrador') || roles.value.includes('Financeiro')) {
      menu.value[transferenciasSaldoIndex].child = [
        {
          href: { name: 'balanceTransfersList' },
          title: 'Consultar Transferências',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Operacional') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador') && !roles.value.includes('Financeiro')
    ) {
      menu.value.splice(aprovacoesIndex, 1);
    }

  }

  ///////////////CONTESTAÇÃO//////////////////
  const contestacaoIndex = menu.value.findIndex(menu => menu.title === 'Contestações');
  if (contestacaoIndex !== -1) {

    if (roles.value.includes('Administrador')) {
      menu.value[contestacaoIndex].child = [
        {
          href: { name: 'contestationList' },
          title: 'Consultar Constestações',
          icon: 'mdi mdi-arrow-right',
        }
      ];
    }

    if (
      roles.value.includes('Operacional') && !roles.value.includes('Administrador')
      || roles.value.includes('Financeiro') && !roles.value.includes('Administrador')
      || roles.value.includes('Comercial') && !roles.value.includes('Administrador')
      || roles.value.includes('Contato') && !roles.value.includes('Administrador')
      || roles.value.includes('Premiado') && !roles.value.includes('Administrador')
      || roles.value.includes('Atendimento') && !roles.value.includes('Administrador')
    ) {
      menu.value.splice(contestacaoIndex, 1);
    }

  }
};


const menu = ref([
  {
    header: '',
    hiddenOnCollapse: false,
  },
  {
    href: { name: 'home' },
    title: 'Início',
    icon: 'mdi mdi-home',
  },
  {
    // href: {name: 'userCreate'},
    title: 'Cadastros',
    icon: ' mdi mdi-account-multiple-plus',
    child: []
  },
  {
    title: 'Pedidos de Carga',
    icon: 'mdi mdi-cart',
    child: []
  },
  {
    title: 'Notas Fiscais',
    icon: 'mdi mdi-note-multiple',
    child: [],
  },
  {
    title: 'Vincular Saldo Piscina',
    icon: 'mdi mdi-code-not-equal',
    child: [],
  },
  {
    title: 'Aprovar Cargas',
    icon: 'mdi mdi-credit-card',
    child: [],
  },
  // {
  //   title: 'Recargas',
  //   icon: 'mdi mdi-credit-card',
  //   child: [],
  // },
  {
    title: 'Relatórios',
    icon: 'mdi mdi-clipboard',
    child: [],
  },
  {
    title: 'Embossing',
    icon: 'mdi mdi-credit-card-multiple',
    child: [],
  },
  {
    title: 'Usuários',
    icon: 'mdi mdi-credit-card-multiple',
    child: [],
  },
  {
    title: 'Cartões',
    icon: 'mdi mdi-credit-card-multiple',
    child: [],
  },
  {
    title: 'Aprovações',
    icon: 'mdi mdi-credit-card-multiple',
    child: [],
  },
  {
    title: 'Transferências de Saldo',
    icon: 'mdi mdi-credit-card-multiple',
    child: [],
  },
  {
    title: 'Contestações',
    icon: 'mdi mdi-note-multiple',
    child: [],
  },
]);
const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
  showOneChild: {
    type: Boolean,
    default: true,
  },
});

// };
</script>

<style>
.v-sidebar-menu {
  background: #fff;
}

.v-sidebar-menu .vsm--dropdown {
  background: #e3e3e3;
}

.v-sidebar-menu .vsm--link_hover {
  background: #e8eff4;
}

.v-sidebar-menu .vsm--link .vsm--icon {
  background: #bbc5d6;
  color: #000;
}
.v-sidebar-menu .vsm--link_active .vsm--icon {
  background: #000;
  color: #fff;
}

.v-sidebar-menu .vsm--link_open {
  background: #4285f4;
}

.v-sidebar-menu .vsm--link_open .vsm--icon {
  background: none;
  color: #fff;
}

.v-sidebar-menu .vsm--title {
  color: #000;
}

.v-sidebar-menu .vsm--link_open .vsm--title {
  color: #fff;
}
</style>



